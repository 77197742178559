import { render, staticRenderFns } from "./TableRowBalanceAccountValue.vue?vue&type=template&id=5a841939"
import script from "./TableRowBalanceAccountValue.vue?vue&type=script&setup=true&lang=ts"
export * from "./TableRowBalanceAccountValue.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SimpleTableRow: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/SimpleTable/SimpleTableRow.vue').default})
