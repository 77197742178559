// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cell_i3TJx{padding:0 .2rem}tr:not(:hover) .even_lC3AK{background-color:var(--color-supportive-3-50)!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cell": "cell_i3TJx",
	"even": "even_lC3AK"
};
module.exports = ___CSS_LOADER_EXPORT___;
